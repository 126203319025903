import React from "react";
import tw from "twin.macro";
import { Link, graphql } from "gatsby";
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Layout from "../components/layout.jsx";
import Tag from "../components/tag.jsx";
import kebabCase from "lodash/kebabCase";

const BlogTags = ({ data: {allMdx: {group}} }) => {
  return (
    <Layout>
      <h1 tw="text-2xl leading-10 font-bold mt-6">
        Tag Center
      </h1>
      <GatsbySeo title="Tag Center"/>
      <hr tw="border my-2" />
      {group.map(tag => (
        <div key={tag.tag} tw="mb-10">
          <div>
            <Tag to={`/tags/${kebabCase(tag.tag)}/`}>
              {tag.tag}
            </Tag>
            {tag.totalCount} article
          </div>
          {tag.edges.map(({node: post}) => (
            <article key={post.id}>
              <Link tw="text-blue-ocean" to={post.fields.slug}>
                <h2 tw="text-xl leading-9 font-bold mt-5">{post.frontmatter.title||'Untitled'}</h2>
              </Link>
              <p>{post.excerpt}</p>
            </article>
          ))}
        </div>
      ))}
    </Layout>
  );
};

export const pageQuery = graphql`
query tagsIndex {
  allMdx (sort: {fields: [frontmatter___date], order: DESC}) {
    group(field: frontmatter___tags) {
      tag: fieldValue
      totalCount
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            date
          }
          fields {
            slug
          }
        }
      }
    }
  }
}
`;

export default BlogTags;
